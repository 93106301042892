@font-face {
  font-family: "Kabel LT Std";
  src: url("./fonts/kabel/KabelLTStd-Book.woff2") format("woff2"),
    url("./fonts/kabel/KabelLTStd-Book.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Kabel LT Std";
  src: url("./fonts/kabel/KabelLTStd-Heavy.woff2") format("woff2"),
    url("./fonts/kabel/KabelLTStd-Heavy.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Kabel LT Std";
  src: url("./fonts/kabel/KabelLTStd-Black.woff2") format("woff2"),
    url("./fonts/kabel/KabelLTStd-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

html {
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.75;
}

@media (max-width: 800px) {
  html {
    font-size: 14px;
  }
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html,
body {
  margin: 0;
  padding: 0;
}

body {
  background-color: white;
  color: black;
  -webkit-font-smoothing: antialiased;
  font-family: "Kabel LT Std", sans-serif;
}

h1,
h2,
h3 {
  font-weight: normal;
  text-align: center;
}

a {
  color: inherit;
  text-decoration: none;
}
